import React from 'react';
import styled from 'styled-components';
import { Query } from '../graphql-types';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Container,
  Jumbotron,
  Row,
} from 'reactstrap';
import Layout from '../components/Layout';
import MainContainer from '../components/MainContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faHeart,
  faMapMarkerAlt,
  faPhone,
  faPoundSign,
  faUser,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import { MainButton } from '../components/Button';
import Modali, { useModali } from 'modali';
import { ModalTherapistCard } from '../components/TherapistCard';
import {
  getTherapyViaIconName,
  TherapyViaOptions,
} from '../screens/auth/TherapistAuth';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { articleRichTextOptions } from '../utils/contentful';
import Box from '../reusecore/src/elements/Box';
import { theme } from '../theme';
import Text from '../reusecore/src/elements/Text/text';
import { darken, lighten } from 'polished';
import ThemeButton from '../reusecore/src/elements/Button/button';
import { flatMap } from 'lodash-es';

export interface TherapistProfileTemplateProps {
  data: Query;
}

const ConstrainingContainer = styled(Container)`
  max-width: 500px;
`;

const FIXED_SPECIALISMS: string[] = [
  'Anxiety',
  'Depression',
  'Stress',
  'Minority Stress',
];

export const HorizontalRule = styled.div`
  background-color: ${darken(0.1, '#dbe3e7')};
  border-style: none;
  color: #536171;
  height: 1px;
  line-height: 16px;
  margin: 0;
  width: 100%;
`;

const TherapistProfileTemplate: React.FunctionComponent<TherapistProfileTemplateProps> = ({
  data,
}): JSX.Element => {
  const { contentfulTherapist } = data;

  const [connectModal, toggleConnectModal] = useModali({
    animated: true,
    closeButton: false,
  });

  let richTextComponent;
  if (data.contentfulTherapist.richTextBio) {
    richTextComponent = documentToReactComponents(
      data.contentfulTherapist.richTextBio.json
    );
  }

  return (
    <Layout>
      <Container
        fluid
        style={{ padding: 0, backgroundColor: theme.colors.sand }}
      >
        <Container>
          <Row>
            <Col
              sm={{ size: 6, offset: 3 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
                pt={4}
              >
                <Image
                  fluid={contentfulTherapist.profilePhoto.fluid}
                  style={{
                    height: theme.sizes[10],
                    width: theme.sizes[10],
                    borderRadius: '50%',
                  }}
                />
                <Text
                  fontFamily="heading"
                  fontWeight={3}
                  fontSize={3}
                  pt={3}
                  pb={1}
                  lineHeight="normal"
                  m={0}
                >
                  {contentfulTherapist.fullName}
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  pb={3}
                  lineHeight="normal"
                  m={0}
                >
                  {contentfulTherapist.title}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  textAlign="center"
                  pb={3}
                >
                  {contentfulTherapist.headline.headline}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    <FontAwesomeIcon icon={faPoundSign} size="lg" />
                    <span style={{ marginLeft: theme.space[2] }}>Price</span>
                  </Text>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    £{contentfulTherapist.sessionPricing} / session
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    <FontAwesomeIcon icon={faHeart} size="lg" />
                    <span style={{ marginLeft: theme.space[2] }}>
                      Sexuality
                    </span>
                  </Text>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {contentfulTherapist.sexuality}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    <FontAwesomeIcon icon={faUser} size="lg" />
                    <span style={{ marginLeft: theme.space[2] }}>Gender</span>
                  </Text>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {contentfulTherapist.gender}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    <FontAwesomeIcon icon={faComments} size="lg" />
                    <span style={{ marginLeft: theme.space[2] }}>
                      Therapy Via
                    </span>
                  </Text>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {contentfulTherapist.therapyVia.reduce(
                      (a, b) => a + ', ' + b
                    )}
                  </Text>
                </Box>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>

          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  About
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={3}
                >
                  {flatMap(
                    contentfulTherapist.bio.bio.split('\n'),
                    (text, i) => [i > 0 && <br />, text]
                  )}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
              >
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    pb={2}
                  >
                    Therapy Types
                  </Text>
                </Box>
                <Box flexBox width="100%" pb={4}>
                  {[
                    ...(data.contentfulTherapist.therapyTypes || []),
                    ...(data.contentfulTherapist.additionalTherapyTypes || []),
                  ].map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    pb={2}
                  >
                    Specialisms
                  </Text>
                </Box>
                <Box flexBox flexWrap="wrap" width="100%" pb={4}>
                  {[
                    ...(data.contentfulTherapist.specialisms || []),
                    ...(data.contentfulTherapist.additionalSpecialisms || []),
                    ...FIXED_SPECIALISMS,
                  ].map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    pb={2}
                  >
                    Issues Worked With
                  </Text>
                </Box>
                <Box flexBox flexWrap="wrap" width="100%" pb={4}>
                  {data.contentfulTherapist.issuesWorkedWith.map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    pb={2}
                  >
                    Qualifications
                  </Text>
                </Box>
                <Box
                  flexBox
                  flexDirection="column"
                  flexWrap="wrap"
                  width="100%"
                  pb={4}
                >
                  {data.contentfulTherapist.qualifications.map(x => (
                    <Text
                      key={x}
                      fontFamily="body"
                      fontWeight={1}
                      fontSize={0}
                      lineHeight="normal"
                      m={0}
                    >
                      {x}
                    </Text>
                  ))}
                </Box>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  Payment
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  <span style={{ fontWeight: theme.fontWeights[3] }}>
                    £{contentfulTherapist.sessionPricing}
                  </span>{' '}
                  per session
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  {flatMap(
                    contentfulTherapist.pricingConditions.pricingConditions.split(
                      '\n'
                    ),
                    (text, i) => [i > 0 && <br />, text]
                  )}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  Find Me At
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  {contentfulTherapist.address.address}
                </Text>
                <Box height={theme.sizes[9]} width="100%" pb={5}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: 'AIzaSyCkHaE09Mwm4aYwC0z_q-eEmna-0zHje6Y',
                      language: 'en-GB',
                      region: 'uk',
                    }}
                    defaultCenter={{
                      lat: data.contentfulTherapist.location.lat,
                      lng: data.contentfulTherapist.location.lon,
                    }}
                    zoom={14}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      color="red"
                      size="2x"
                    />
                  </GoogleMapReact>
                </Box>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
                pb={4}
              >
                <ThemeButton px={5} py={2} onClick={toggleConnectModal}>
                  Connect
                </ThemeButton>
              </Box>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modali.Modal {...connectModal}>
        <ModalTherapistCard
          profileImageSrc={data.contentfulTherapist.profilePhoto.fluid.src}
          fullName={data.contentfulTherapist.fullName}
          email={data.contentfulTherapist.email}
          phone={data.contentfulTherapist.phoneNumber}
          onClick={toggleConnectModal}
        />
      </Modali.Modal>
    </Layout>
  );
};

export default TherapistProfileTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTherapist(slug: { eq: $slug }) {
      slug
      therapyTypes
      additionalTherapyTypes
      specialisms
      additionalSpecialisms
      dateOfBirth
      sexuality
      gender
      ethnicity
      sessionPricing
      profilePhoto {
        fluid(maxWidth: 1000) {
          src
        }
      }
      profileVideo {
        file {
          url
        }
      }
      fullName
      headline {
        headline
      }
      title
      therapyVia
      bio {
        bio
      }
      richTextBio {
        json
      }
      issuesWorkedWith
      qualifications
      consultationFee
      pricingConditions {
        pricingConditions
      }
      insurers
      location {
        lat
        lon
      }
      address {
        address
      }
      email
      phoneNumber
    }
  }
`;
