import React from 'react';
import { Card, CardBody, CardImg, CardTitle } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { MainButton } from '../Button';
import { navigate } from '@reach/router';

export type UserTherapistCardProps = {
  slug: string;
  profileImageSrc: string;
  score: number;
  fullName: string;
  title: string;
  distanceMiles: number;
  headline?: string;
  pricePerSession: number;
  therapyTypes: any;
  specialisms: any;
  gender: any;
  sexuality: any;
  ethnicity: any;
};

const TherapistCardCollar = styled(CardTitle)`
  text-align: left;
  padding: 1rem 1.5rem;
  background-color: #0f6674;
  color: white;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

const TherapistCardBody = styled(CardBody)`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
`;

const TherapistCardName = styled.h4`
  text-align: left;
  font-weight: 700;
  margin-bottom: 0;
`;

const TherapistCardSubtitle = styled.h6`
  text-align: left;
  margin-bottom: 2rem;
  color: #868e96;
`;

const TherapistCardButton = styled(MainButton)`
  align-self: center;
  width: 75%;
`;

export const UserTherapistCard: React.FunctionComponent<UserTherapistCardProps> = ({
  slug,
  title,
  children,
  headline,
  fullName,
  distanceMiles,
  pricePerSession,
  profileImageSrc,
  score,
}): JSX.Element => {
  return (
    <Card className="mb-5">
      <CardImg top className="img-fluid" src={profileImageSrc} />
      <TherapistCardCollar>
        {/* Full name with final initial as first letter.*/}
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{ color: 'white' }}
          className="mr-2"
          size="lg"
        />
        {Math.round(score)}% match
      </TherapistCardCollar>
      <TherapistCardBody>
        <TherapistCardName>
          {fullName
            .trim()
            .split(/\s+/)
            // @TODO: NOTES/ reduce() full signature.
            .reduce(
              (acc, cur, ix, arr) =>
                acc + ' ' + (ix === arr.length - 1 ? cur[0] + '.' : cur)
            )}
        </TherapistCardName>
        <TherapistCardSubtitle>{title}</TherapistCardSubtitle>
        <TherapistCardButton
          type="primary"
          onClick={() => {
            navigate(`/app/therapist/${slug}`);
          }}
        >
          View Profile
        </TherapistCardButton>
      </TherapistCardBody>
    </Card>
  );
};
