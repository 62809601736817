import React, { HTMLAttributes } from 'react';
import { Card, CardBody, CardImg, CardTitle } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faEnvelope,
  faInfoCircle,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { navigate } from '@reach/router';
import { CardFooter } from 'reactstrap';
import Button from '../../reusecore/src/elements/Button/button';

export interface ModalTherapistCardProps
  extends HTMLAttributes<HTMLDivElement> {
  profileImageSrc?: string;
  fullName: string;
  phone?: string;
  email: string;
  onClick: () => void;
}

const TherapistCardCollar = styled(CardTitle)`
  text-align: left;
  padding: 1rem 1.5rem;
  background-color: #0f6674;
  color: white;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

const TherapistCardBody = styled(CardBody)`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
`;

const TherapistCardName = styled.h4`
  text-align: left;
  font-weight: 700;
  margin-bottom: 0;
`;

const TherapistCardSubtitle = styled.h6`
  text-align: left;
  margin-bottom: 1rem;
  color: #868e96;
`;
const TherapistCardInfo = styled.h6`
  text-align: left;
  color: #868e96;
  margin-bottom: 0;
`;

const TherapistCardFooter = styled(CardFooter)`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
`;

export const ModalTherapistCard: React.FunctionComponent<ModalTherapistCardProps> = ({
  children,
  onClick,
  style,
  profileImageSrc,
  fullName,
  phone,
  email,
}): JSX.Element => {
  return (
    <Card style={style}>
      <TherapistCardBody>
        <TherapistCardInfo>
          Arrange a consultation with {fullName.split(/\s+/)[0]} by contacting
          them with the details below.
        </TherapistCardInfo>
      </TherapistCardBody>
      <TherapistCardFooter>
        {!!phone && (
          <TherapistCardSubtitle>
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            {phone}
          </TherapistCardSubtitle>
        )}
        <TherapistCardSubtitle>
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          {email}
        </TherapistCardSubtitle>
        <Button px={5} py={2} onClick={onClick}>
          Done
        </Button>
      </TherapistCardFooter>
    </Card>
  );
};
