import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulTherapist, MatchesQuery } from '../../graphql-types';
import { navigate, RouteComponentProps } from '@reach/router';
import { Categories } from './EvaluationAuth';
import { Badge, Col, Container, Jumbotron, Row } from 'reactstrap';
import MainContainer from '../../components/MainContainer';
import Layout from '../../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faComments,
  faEnvelope,
  faHeart,
  faMapMarkerAlt,
  faMobile,
  faPhone,
  faPlay,
  faPoundSign,
  faUser,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import { MainButton } from '../../components/Button';
import Modali, { useModali } from 'modali';
import { ModalTherapistCard } from '../../components/TherapistCard';
import ReactPlayer from 'react-player';
import { theme } from '../../theme';
import Image from 'gatsby-image';
import Text from '../../reusecore/src/elements/Text/text';
import { flatMap } from 'lodash-es';
import ThemeButton from '../../reusecore/src/elements/Button/button';
import { HorizontalRule } from '../../templates/therapist-profile';
import Box from '../../reusecore/src/elements/Box';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { getFirebaseAuth, getFirebaseFirestore } from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getLogger } from '@graphql-codegen/cli/utils/logger';
import { getDistanceFromLatLonInKm } from '../../utils/match';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { articleRichTextOptions } from '../../utils/contentful';

export interface TherapistPageProps
  extends RouteComponentProps<{
    therapistId: string;
  }> {}

export type TherapyViaOptions =
  | 'Face-to-face'
  | 'Video'
  | 'SMS Messaging'
  | 'Email';

export const getTherapyViaIconName = (
  therapyViaOption: TherapyViaOptions
): IconDefinition => {
  switch (therapyViaOption) {
    case 'Email':
      return faEnvelope;
    case 'Face-to-face':
      return faComments;
    case 'SMS Messaging':
      return faMobile;
    case 'Video':
      return faVideo;
    default:
      throw new Error('No icon found.');
  }
};

const PlayerOverlay = styled.div<{ playing: boolean }>`
  position: absolute;
  top: 0;
  opacity: ${props => (props.playing ? 0 : 0.25)};
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FIXED_SPECIALISMS: string[] = [
  'Anxiety',
  'Depression',
  'Stress',
  'Minority Stress',
];

const TherapistAuth: React.FunctionComponent<TherapistPageProps> = ({
  therapistId,
}): JSX.Element => {
  const {
    allContentfulTherapist: { edges: therapistData },
  } = useStaticQuery<MatchesQuery>(graphql`
    query Therapist {
      allContentfulTherapist {
        edges {
          node {
            slug
            therapyTypes
            additionalTherapyTypes
            specialisms
            additionalSpecialisms
            dateOfBirth
            sexuality
            gender
            ethnicity
            sessionPricing
            profilePhoto {
              fluid(maxWidth: 1000) {
                src
              }
            }
            profileVideo {
              file {
                url
              }
            }
            fullName
            therapyVia
            headline {
              headline
            }
            title
            bio {
              bio
            }
            richTextBio {
              json
            }
            issuesWorkedWith
            qualifications
            consultationFee
            pricingConditions {
              pricingConditions
            }
            insurers
            location {
              lat
              lon
            }
            address {
              address
            }
            email
            phoneNumber
            consultationId
          }
        }
      }
    }
  `);

  const [user, loading, error] = useAuthState(getFirebaseAuth());
  const db = getFirebaseFirestore();
  const [userSnapshot, userLoading, userError] = useDocumentOnce(
    !db ? null : db.collection('users').doc(user.uid)
  );

  const [distance, setDistance] = useState<number>(undefined);

  const [therapistInfo, setTherapistInfo] = useState<ContentfulTherapist>(null);
  const [connectModal, toggleConnectModal] = useModali({
    animated: true,
    closeButton: false,
    centered: true,
  });
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  useEffect(() => {
    const filteredTherapist = therapistData.filter(
      x => x.node.slug === therapistId
    );
    if (!filteredTherapist.length) {
      navigate('/app/user');
    } else {
      setTherapistInfo(
        filteredTherapist.map(x => x.node as ContentfulTherapist)[0]
      );
    }
  }, [therapistData, therapistId]);

  useEffect(() => {
    if (db && userSnapshot && therapistInfo) {
      setDistance(
        getDistanceFromLatLonInKm(
          therapistInfo.location.lat,
          therapistInfo.location.lon,
          userSnapshot.data().lat,
          userSnapshot.data().long
        )
      );
    }
  }, [db, therapistInfo, userSnapshot]);

  if (!therapistInfo) {
    return (
      <Container
        fluid
        className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  return (
    <Layout contrast>
      <Container
        fluid
        style={{ padding: 0, backgroundColor: theme.colors.sand }}
      >
        <Container>
          <Row>
            <Col
              sm={{ size: 6, offset: 3 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
                pt={4}
              >
                <Image
                  fluid={therapistInfo.profilePhoto.fluid}
                  style={{
                    height: theme.sizes[11],
                    width: '100%',
                    borderRadius: theme.radii[3],
                  }}
                />
                <Text
                  fontFamily="heading"
                  fontWeight={3}
                  fontSize={3}
                  pt={3}
                  pb={1}
                  lineHeight="normal"
                  m={0}
                >
                  {therapistInfo.fullName}
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={1}
                  pb={3}
                  lineHeight="normal"
                  m={0}
                >
                  {therapistInfo.title}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  textAlign="center"
                  pb={3}
                >
                  {therapistInfo.headline.headline}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faPoundSign} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Price
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    £{therapistInfo.sessionPricing} / session
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faHeart} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Sexuality
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {therapistInfo.sexuality}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faUser} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Gender
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {therapistInfo.gender}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faComments} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Therapy Via
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {therapistInfo.therapyVia.reduce((a, b) => a + ', ' + b)}
                  </Text>
                </Box>
                <Box flexBox width="100%" justifyContent="space-between" pb={3}>
                  <Box
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                    flexBox
                    alignItems="center"
                  >
                    <Box
                      flexBox
                      width={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                    </Box>
                    <Text
                      fontFamily="body"
                      fontWeight={3}
                      fontSize={0}
                      lineHeight="normal"
                      letterSpacing="tracked"
                      m={0}
                      ml={3}
                      style={{ textTransform: 'uppercase' }}
                    >
                      Distance
                    </Text>
                  </Box>
                  <Text
                    fontFamily="body"
                    fontWeight={1}
                    fontSize={0}
                    lineHeight="normal"
                    m={0}
                  >
                    {distance
                      ? `${Math.min(20, Math.ceil(distance))}${
                          distance > 20 ? '+' : ''
                        }km away`
                      : ''}
                  </Text>
                </Box>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>

          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={3}
                  fontSize={1}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={0}
                  style={{
                    textTransform: 'uppercase',
                    alignSelf: 'flex-start',
                  }}
                >
                  About
                </Text>
                <Box position="relative" mb={3}>
                  {therapistInfo.profileVideo && (
                    <>
                      <ReactPlayer
                        url={therapistInfo.profileVideo.file.url}
                        playing={videoPlaying}
                        className="w-100"
                        config={{ file: { attributes: { type: 'video/mp4' } } }}
                      />
                      <PlayerOverlay
                        onClick={() => {
                          setVideoPlaying(!videoPlaying);
                        }}
                        playing={videoPlaying}
                      >
                        <FontAwesomeIcon
                          icon={faPlay}
                          color="white"
                          size="3x"
                        />
                      </PlayerOverlay>
                    </>
                  )}
                </Box>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={3}
                >
                  {documentToReactComponents(
                    therapistInfo.richTextBio.json,
                    articleRichTextOptions
                  )}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
              >
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Therapy Types
                  </Text>
                </Box>
                <Box flexBox width="100%" pb={4}>
                  {[
                    ...(therapistInfo.therapyTypes || []),
                    ...(therapistInfo.additionalTherapyTypes || []),
                  ].map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Specialisms
                  </Text>
                </Box>
                <Box flexBox flexWrap="wrap" width="100%" pb={4}>
                  {[
                    ...(therapistInfo.specialisms || []),
                    ...(therapistInfo.additionalSpecialisms || []),
                    ...FIXED_SPECIALISMS,
                  ].map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Issues Worked With
                  </Text>
                </Box>
                <Box flexBox flexWrap="wrap" width="100%" pb={4}>
                  {therapistInfo.issuesWorkedWith.map(x => (
                    <Badge
                      key={x}
                      pill
                      style={{
                        padding: '5px 10px',
                        marginRight: theme.space[2],
                        backgroundColor: theme.colors.primary,
                        marginBottom: theme.space[2],
                      }}
                    >
                      <Text
                        fontFamily="body"
                        fontWeight={1}
                        fontSize={0}
                        lineHeight="normal"
                        m={0}
                      >
                        {x}
                      </Text>
                    </Badge>
                  ))}
                </Box>
                <Box flexBox width="100%">
                  <Text
                    fontFamily="body"
                    fontWeight={3}
                    fontSize={0}
                    lineHeight="normal"
                    letterSpacing="tracked"
                    m={0}
                    pb={2}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Qualifications
                  </Text>
                </Box>
                <Box
                  flexBox
                  flexDirection="column"
                  flexWrap="wrap"
                  width="100%"
                  pb={4}
                >
                  {therapistInfo.qualifications.map(x => (
                    <Text
                      key={x}
                      fontFamily="body"
                      fontWeight={1}
                      fontSize={0}
                      lineHeight="normal"
                      m={0}
                      as="li"
                    >
                      {x}
                    </Text>
                  ))}
                </Box>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={3}
                  fontSize={1}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={3}
                  style={{ textTransform: 'uppercase' }}
                >
                  Payment
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  <span style={{ fontWeight: theme.fontWeights[3] }}>
                    £{therapistInfo.sessionPricing}
                  </span>{' '}
                  per session
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  {flatMap(
                    therapistInfo.pricingConditions.pricingConditions.split(
                      '\n'
                    ),
                    (text, i) => [i > 0 && <br key={text + i} />, text]
                  )}
                </Text>
                <HorizontalRule style={{ marginBottom: theme.space[3] }} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <Text
                  fontFamily="body"
                  fontWeight={3}
                  fontSize={1}
                  lineHeight="normal"
                  letterSpacing="tracked"
                  m={0}
                  pb={3}
                  style={{ textTransform: 'uppercase' }}
                >
                  Find Me At
                </Text>
                <Text
                  fontFamily="body"
                  fontWeight={1}
                  fontSize={0}
                  lineHeight="normal"
                  m={0}
                  pb={2}
                >
                  {therapistInfo.address.address}
                </Text>
                <Box height={theme.sizes[9]} width="100%" pb={5}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: 'AIzaSyDNe66TuFxob3ouMcYmhKOCiimqGHOsRTs',
                      language: 'en-GB',
                      region: 'uk',
                    }}
                    defaultCenter={{
                      lat: therapistInfo.location.lat,
                      lng: therapistInfo.location.lon,
                    }}
                    zoom={14}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      color="red"
                      size="2x"
                    />
                  </GoogleMapReact>
                </Box>
              </Box>
            </Col>
          </Row>
          {!!therapistInfo.consultationId && (
            <Row>
              <Col sm={{ offset: 2, size: 8 }}>
                <Box
                  flexBox
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  pb={3}
                >
                  <ThemeButton
                    py={2}
                    width={9}
                    onClick={() => {
                      window.location.assign(
                        `https://helsaconsultation.com/${therapistInfo.consultationId}`
                      );
                    }}
                  >
                    Book Consultation
                  </ThemeButton>
                </Box>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={{ offset: 2, size: 8 }}>
              <Box
                flexBox
                flexDirection="column"
                alignItems="center"
                width="100%"
                pb={4}
              >
                <ThemeButton
                  px={5}
                  width={9}
                  onClick={async () => {
                    toggleConnectModal();
                    if (process.env.NODE_ENV === 'production') {
                      await db.collection('mail').add({
                        to: [therapistInfo.email, 'enquiries@helsahelps.com'],
                        message: {
                          subject: 'Helsa Connection Alert',
                          // eslint-disable-next-line max-len
                          text: `Hello ${therapistInfo.fullName}! We’re dropping you a quick line to let you know that a Helsa user has matched with you and has clicked 'connect'. The user has received your contact information and may be getting in touch separately. Best, Helsa Team`,
                          // eslint-disable-next-line max-len
                          html: `Hi ${therapistInfo.fullName},<br/><br/>We’re dropping you a quick line to let you know that a Helsa user has matched with you and has clicked 'connect'.<br/><br/>The user has received your contact information and may be getting in touch separately.<br/><br/>Best,<br/>Helsa Team`,
                        },
                      });
                    }
                  }}
                >
                  Contact Info
                </ThemeButton>
              </Box>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modali.Modal {...connectModal}>
        <ModalTherapistCard
          profileImageSrc={therapistInfo.profilePhoto.fluid.src}
          fullName={therapistInfo.fullName}
          email={therapistInfo.email}
          phone={therapistInfo.phoneNumber}
          onClick={() => {
            toggleConnectModal();
          }}
        />
      </Modali.Modal>
    </Layout>
  );
};

export default TherapistAuth;
